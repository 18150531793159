
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCurrentUser } from "@/utils/index"
import { getDashboardECPWelcomeData } from "@/api/dashboard"
import ECP_EDP_DashboardNavigator from "@/components/cards/ECP_EDP_DashboardNavigator.vue"

import { getOVICoverageInfo } from "@/api/ovi_api";


export default defineComponent({
  name: "reminderspage",
  components: {
    ECP_EDP_DashboardNavigator
  },
  data() {
    return { 
      user: {},
      ecp_welcome_data: {},
      r2meLink: "",
      r2meLinkLabel: "",
      isLoading: true,
      pt : "" ,
      allowClose : true,
      renewBannerFlag: false,
      unilevelMaintenancePromptMessage : "",
      isShowQualifiedMessage : false,
      
    }
  },
  mounted(){
      this.getPT();
      this.user = getCurrentUser();
      this.getDashboardECPWelcomeData();
      this.getOviCoverageInfo();
  },
  methods : {
    async getDashboardECPWelcomeData(){
      this.isLoading = true;
      const response = await getDashboardECPWelcomeData();
      console.log(response.data);

      this.ecp_welcome_data = response.data;

      this.unilevelMaintenancePromptMessage = response.data.unilevelMaintenancePromptMessage;
      if(this.unilevelMaintenancePromptMessage.includes("Qualified for next month") ||
          this.unilevelMaintenancePromptMessage.includes("FREE MAINTENANCE")){
        this.isShowQualifiedMessage = true;
      } else {
        this.isShowQualifiedMessage = false;
      }

      

      if(this.pt == "ovi"){
        this.r2meLink = "https://shop.orbixvictus.com/?ecpid=" + response.data.firstUsidInPlacement;
        this.r2meLinkLabel = "OVI Shop"
      } else {
        this.r2meLink = "https://shop.empoweredconsumerism.com/?ecpid=" + response.data.firstUsidInPlacement;
        this.r2meLinkLabel = "EC R2ME";
      }
      
      this.isLoading = false;
    },
    async getPT(){
       this.pt = String(localStorage.getItem("pt"));
    },
    async showRetroPMAPage(){
      this.$router.push('/retropma') ;
    },
    
    async getOviCoverageInfo(){ 
      const response = await getOVICoverageInfo(); 
      if(response.data !== undefined && response.data.activeCoverage != undefined) {
        let activeCoverage  = response.data.activeCoverage;
        for(let i = 0; i < activeCoverage.length; i++) {
            if(activeCoverage[i].coveragests === 'EXPIRING' || activeCoverage[i].coveragests === 'EXPIRED' ) {
              this.renewBannerFlag = true;
              break;
            }
        }
      }
      
    }
     
  },
  setup() {
    
  }
});
